import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { createColumnHelper } from '@tanstack/react-table';

import { useAuth } from '../../context/auth-context';
import Table from '../../components/Table';
import { fetchData } from '../../utils/fetchers';

const columnHelper = createColumnHelper();

const columns = [
    columnHelper.accessor('toggle', {
        id: 'toggle',
        cell: () => <div></div>,
        header: ''
    }),
    columnHelper.accessor('name', {
        id: 'name',
        cell: info => info.renderValue(),
        header: 'Företagsnamn'
    }),
    columnHelper.accessor('created', {
        id: 'created',
        cell: info => {
            const date = info.renderValue();
            return new Date(date).toLocaleDateString('sv-SE');
        },
        header: 'Skapad'
    }),
    columnHelper.accessor('numberOfEmployees', {
        id: 'numberOfEmployees',
        cell: info => info.renderValue(),
        header: 'Antal anställda'
    }),
    columnHelper.accessor('companyTotalDiscount', {
        id: 'companyTotalDiscount',
        cell: info => {
            const decimalDiscount = info.renderValue();
            return (decimalDiscount * 100).toFixed(2) + "%";
        },
        header: 'Rabatt'
    }),
    columnHelper.accessor('status', {
        id: 'status',
        cell: info => info.renderValue(),
        header: 'Status'
    })
];

function Dsp() {
    const [inquiries, setInquiries] = useState(null);

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    useEffect(() => {
        let isMounted = true;

        if (!isAuthed) {
            navigate("/login");
        } else {
            if(isMounted) {
                (async () => {
                    const res = await fetchData("/api/dsp/companies");

                    if (res.status === 401) {
                        sessionStorage.removeItem('cId');
                        setIsAuthed(false);
                        return navigate("/login");
                    } else {
                        setInquiries(res.data);
                    }
                })();            
            }
        }

        return () => isMounted = false;
    }, [navigate, isAuthed, setIsAuthed]);

    return (
        <div>
            <h1>DSP</h1>
            {inquiries?.length > 0 &&
                <Table columns={columns} data={inquiries} />
            }
        </div>
    )
}

export default Dsp;