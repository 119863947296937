import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { FcCheckmark } from "react-icons/fc";
import styled from "styled-components";

import { useAuth } from '../../context/auth-context';
import Accordion from '../../components/Accordion';
import { fetchData, createData } from '../../utils/fetchers';

const ENV = process.env.REACT_APP_PUBLIC_ENV;

const initialData = {
    name: "",
    code: "",
}

function Vas() {
    const [vasOptions, setVasOptions] = useState(null);
    const [newOptionValues, setNewOptionValues] = useState(initialData);
    const [newOptionParentId, setNewOptionParentId] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    const getVasPlacements = useCallback(async () => {
        const res = await fetchData("/api/dsp/vas/placements");

        if (res.status === 401) {
            sessionStorage.removeItem('cId');
            setIsAuthed(false);
            return navigate("/login");
        } else {
            setVasOptions(res.data);
        }
    }, [navigate, setIsAuthed])

    const createOption = async () => {
        if (!newOptionValues?.name || newOptionValues.name === "")
            setError("Du måste ange ett namn")
        else if (!newOptionValues?.code || newOptionValues.code === "")
            setError("Du måste ange en kod")
        else if (!newOptionParentId || newOptionParentId === "")
            setError("Du måste välja en typ");
        else {
            try {
                const res = await createData("/api/dsp/vas/placements", {
                    vasTypeId: newOptionParentId,
                    name: newOptionValues.name,
                    code: newOptionValues.code,
                });
        
                if (res?.status === 200) {
                    getVasPlacements();
                    setNewOptionValues(initialData);
                    setSuccess(res?.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 3500)
                } else {
                    console.log(res?.validation?.hasErrors);
                    if (res?.validation?.hasErrors)
                        setError(res?.validation?.messages[0])
                    else
                        setError(res?.message);
                    setTimeout(() => {
                        setError(null);
                    }, 3500)
                }   
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleOnChange = (e) => {
        setNewOptionValues(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }

    const handleSelectOnChange = (e) => {
        setNewOptionParentId(e.target.value);
    }

    useEffect(() => {
        let isMounted = true;

        if (!isAuthed) {
            navigate("/login");
        } else {
            if(isMounted) {
                getVasPlacements();           
            }
        }

        return () => isMounted = false;
    }, [navigate, isAuthed, getVasPlacements]);

    return (
        <div style={{ overflow: "scroll" }}>
            <h1>VAS</h1>
            {vasOptions?.length < 4 &&
                <>
                    <NewOptionContainer>
                        <div>
                            <select name="parentId" id="parentId" onChange={handleSelectOnChange}>
                            <option value="">Välj typ...</option>
                            {ENV === "local" ? 
                                <>
                                <option value="c902b156-83ac-4122-83e6-4072b131ee88">Överdelar</option>
                                <option value="4664dd5c-2443-4980-a6a4-d9096f9f7095">Underdelar</option>
                                <option value="9e144acb-f0bb-46f0-8b77-0dade0118d18">Kepsar/Mössor</option>
                                <option value="4103e527-0334-44b0-86cc-b0051b54dfc1">Tilläggstjänster</option>
                                </> :
                                <>
                                <option value="ecfa75cc-b655-44e2-a650-b8073197679c">Överdelar</option>
                                <option value="5f06f19d-2a33-46e3-a194-eaa7474d6438">Underdelar</option>
                                <option value="6edfdb6b-7b8b-46d8-984a-2ee74b56b669">Kepsar/Mössor</option>
                                <option value="53559c26-1346-4285-9c42-402f5e0d3083">Tilläggstjänster</option>
                                </>
                            }
                            </select>
                            <input 
                                type="text" 
                                name="name"
                                value={newOptionValues.name} 
                                onChange={handleOnChange} 
                                placeholder="Namn"
                            />
                            <input 
                                type="text" 
                                name="code"
                                value={newOptionValues.code} 
                                onChange={handleOnChange} 
                                placeholder="Code"
                            />
                        </div>
                        <div>
                            <div onClick={createOption}><FcCheckmark /></div>
                        </div>
                    </NewOptionContainer>
                    {success && <p className="okay">{success}</p>}
                    {error && <p className="error">{error}</p>}
                </>
            }
            {(vasOptions && vasOptions?.length > 0) &&
                vasOptions.map(x =>
                    <Accordion 
                        key={x.id} 
                        heading={x.name} 
                        content={x.placements} 
                        parentId={x.id} 
                        refetch={getVasPlacements}
                        url={"/api/dsp/vas/placements"}
                        type="vas"
                    />
                )
            }
        </div>
    )
}

export default Vas;

const NewOptionContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;

    > div:first-child {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        select {
            margin-bottom: 10px;
            border: none;
            padding: 5px 10px;
        }

        input {
            padding: 5px 10px;
        }
    }

    > div:last-child {
        background-color: #EEEEEE;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 50%;     
        
        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                font-size: 18px;
            }
        }
    }
`