import React from 'react';
import { FcHighPriority, FcCheckmark } from 'react-icons/fc'

const LogsItem = ({ log }) => {

    const logTypeIsCustomer = log.type === "create customer" || log.type === "update customer";

    return (
        <div>
            {logTypeIsCustomer ?
                <div>
                    <span><b>Type:</b> {log.type}</span>
                    <span><b>Status:</b> {log.status}</span>
                    <span><b>BLK Order ID:</b> {log.orderId}</span>
                    <span><b>Customer ID:</b> {log.id}</span>
                    <span><b>Message:</b> {log.message}</span>
                </div> :
                <div>
                    <span><b>Type:</b> {log.type}</span>
                    <span><b>Status:</b> {log.status}</span>
                    <span><b>BLK Order ID:</b> {log.orderId}</span>
                    <span><b>Order ID:</b> {log.id}</span>
                    <span><b>Message:</b> {log.message}</span>
                </div>
            }
            
            {log.status === "0" ? <FcHighPriority size="1.5em" /> : <FcCheckmark size="1.5em" />}
        </div>
    )
}

export default LogsItem;