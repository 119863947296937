import { useState } from 'react'
import {
    FcPrivacy,
    FcUnlock,
    FcCheckmark
} from "react-icons/fc";
import { MdDeleteOutline } from 'react-icons/md';

function AccordionContent({ data, updateOption, deleteOption, type }) {
    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(type === "discounts" ? (data.discountPercentage * 100).toFixed(2) : type === "faq" ? data.question : data.name);
    const [descValue, setDescValue] = useState((type === "settings" || type === "try-out") ? data.desc : type ===  "faq" ? data.answer : "");
    const [codeValue, setCodeValue] = useState(type === "assortment" && data?.code ? data.code : "");

    const handleEdit = () => {
        setEdit(prev => !prev);
    }

    const handleOnChange = (e) => {
        setValue(e.target.value);
    }

    const handleDescOnChange = (e) => {
        setDescValue(e.target.value);
    }

    const handleUpdate = async () => {
        const res = await updateOption(data.id, (type === "settings" || type === "try-out") ? { name: value, desc: descValue } : type === "faq" ? { question: value, answer: descValue } : (type === "assortment" || type === "vas") ? { code: codeValue, name: value } : { value });
        if (res === "OK")
            handleEdit();
    }

    const handleDelete = () => {
        deleteOption(data.id);
    }

    return (
        <div style={{ backgroundColor: "rgb(191,191,191)" }}>
            <div>
                {(type === "discounts" || type === "try-out") && <label htmlFor="input">{type === "discounts" ? data.numberOfEmployees : data.gender === "female" ? "Dam" : "Herr"}</label>}
                <input type={type === "discounts" ? "number" : "text"} value={value} onChange={handleOnChange} disabled={!edit} id="input" name="input" />
                {(type === "settings" || type === "try-out" || type === "faq") &&
                    <textarea id="desc" name="desc" rows="5" cols="33" value={descValue} onChange={handleDescOnChange} disabled={!edit} />
                }
                {(type === "assortment" || type === "vas") && <input type="text" value={codeValue} onChange={(e) => setCodeValue(e.target.value)} disabled={!edit} />}
            </div>
            {!edit ?
                <div>
                    <div onClick={handleEdit}><FcPrivacy /></div>
                    <div onClick={handleDelete}><MdDeleteOutline /></div>
                </div> :
                <div>
                    <div onClick={handleUpdate}><FcCheckmark /></div>
                    <div onClick={handleEdit}><FcUnlock /></div>
                </div>
                
            }
        </div>
    )
}

export default AccordionContent