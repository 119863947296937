import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { createColumnHelper } from '@tanstack/react-table';

import { useAuth } from '../../context/auth-context';
import Table from '../../components/Table';
import { fetchData } from '../../utils/fetchers';

const columnHelper = createColumnHelper();

const columns = [
    columnHelper.accessor('email', {
        id: 'email',
        cell: info => info.renderValue(),
        header: 'Email'
    }),
    columnHelper.accessor('created', {
        id: 'created',
        cell: info => {
            const date = info.renderValue();
            return new Date(date).toLocaleDateString('sv-SE');
        },
        header: 'Skapad'
    }),
    columnHelper.accessor('reminderDate', {
        id: 'reminderDate',
        cell: info => {
            const date = info.renderValue();
            return new Date(date).toLocaleDateString('sv-SE');
        },
        header: 'Påminnelsedatum'
    }),
];

function Reminders() {
    const [reminders, setReminders] = useState(null);

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    useEffect(() => {
        let isMounted = true;

        if (!isAuthed) {
            navigate("/login");
        } else {
            if(isMounted) {
                (async () => {
                    const res = await fetchData("/api/dsp/reminders");

                    if (res.status === 401) {
                        sessionStorage.removeItem('cId');
                        setIsAuthed(false);
                        return navigate("/login");
                    } else {
                        setReminders(res.data);
                    }
                })();            
            }
        }

        return () => isMounted = false;
    }, [navigate, isAuthed, setIsAuthed]);

    return (
        <div>
            <h1>Påminnelser</h1>
            {reminders?.length > 0 &&
                <Table columns={columns} data={reminders} />
            }
        </div>
    )
}

export default Reminders;