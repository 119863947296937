import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from '../context/auth-context';

const Home = () => {

    const { isAuthed } = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        if (!isAuthed)
            navigate("/login");
    }, [isAuthed, navigate])

    return (
        <div>
            <h1>Gear Shift - Admin</h1>
        </div>
    )
}

export default Home;