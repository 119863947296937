import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { FcCheckmark, FcHighPriority } from "react-icons/fc";
import styled from "styled-components";

// import { useAuth } from "../context/auth-context";

const ResetPassword = () => {
    const initalFormValues = {
        email: "",
    }

    const [formValues, setFormValues] = useState(initalFormValues)
    const [responseData, setResponseData] = useState(null);
    const [responseStatus, setResponseStatus] = useState(null);
    const [error, setError] = useState(null); 

    // let navigate = useNavigate();
    // const { isAuthed, setIsAuthed } = useAuth();

    const handleFormValueChange = (e) => {
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value
        });
    }

    const resetPasswordRequest = (e) => {
        e.preventDefault();
        setResponseData(null);
        setError(null);

        fetch('/api/email/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formValues),
        })
        .then(response => {
            setResponseStatus({
                status: response.status
            });
            return response.json();
        })
        .then(data => {
            setResponseData(data);
            setFormValues(initalFormValues);
        })
        .catch((err) => {
            console.error('Error:', err);
            setError(err)
        });
    }

    return (
        <div>
            <h1>Återställ lösenord</h1>
            <FormContainer>
                <form onSubmit={resetPasswordRequest}>
                    <div className="form-group">
                        <input 
                            type="email" 
                            value={formValues.email} 
                            onChange={handleFormValueChange}
                            id="email"
                            className="userEmail" 
                            placeholder="Email" 
                            name="email" 
                            size="64" 
                            maxLength="64" 
                            required
                            pattern=".+@gearshift\.se"
                            title="En Gear Shift-adress behöver användas som email"
                        />
                        <div>
                            <input type="submit" value="Skicka återställningslänk" className="btn btn-default" />
                        </div>            
                    </div>
                </form>
                {responseData &&
                    <div>
                        {responseStatus.status === 200 ? <FcCheckmark size="1.5em" /> : <FcHighPriority size="1.5em" />}
                        <p>{responseData.message}</p>
                    </div>
                }
                {error &&
                    <div className="error">
                        <p>{error?.message}</p>
                    </div>
                }
            </FormContainer>
        </div>
    )
}

const FormContainer = styled.div`
    margin-top: 20px;
    padding: 50px;
    background-color: var(--color-bg-lighter);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    border-radius: 10px;
    position: relative;

    > div {
        display: flex;
        margin-top: 20px;

        p {
            color: var(--color-text-secondary);
            font-size: 13px;
            margin-left: 10px;
            display: flex;
            align-items: center;
        }
    }

    .form-group {
        display: flex;
        flex-direction: column;

        input {
            background: transparent;
            border: solid black 1px;
            border-radius: 10px;
            padding: 10px 15px;
            font-size: 14px;
            margin-bottom: 15px;
            color: #eee;
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);

            &:last-child {
                margin-bottom: 0;
            }

            &:focus {
                outline: none;
                background: #25272c;
            }
        }

        label {
            color: var(--color-text-gray);
            font-size: 12px;
            font-style: italic;
        }

        > div {
            display: flex;
            justify-content: end;
            align-items: center;
            margin-top: 40px;
        }

        .btn {
            border: solid var(--color-contrast-primary) 2px;
            background: transparent;
            padding: 15px;
            margin-bottom: 0;
            border-radius: 30px;
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            color: var(--color-text-primary);
            width: 200px;

            &:hover {
                background: var(--color-contrast-primary);
                cursor: pointer;
                color: var(--color-text-dark);
            }
        }
    }
`

export default ResetPassword;