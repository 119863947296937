import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { FcCheckmark } from "react-icons/fc";
import styled from "styled-components";

import { useAuth } from '../../context/auth-context';
import Accordion from '../../components/Accordion';
import { fetchData, createData } from '../../utils/fetchers';

function Discounts() {
    const [discounts, setDiscounts] = useState(null);
    const [newOptionValue, setNewOptionValue] = useState("");
    const [numberOfEmployees, setNumberOfEmployees] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    const getDiscounts = useCallback(async () => {
        const res = await fetchData("/api/dsp/discounts");

        if (res.status === 401) {
            sessionStorage.removeItem('cId');
            setIsAuthed(false);
            return navigate("/login");
        } else {
            setDiscounts(res.data);
        }
    }, [navigate, setIsAuthed])

    const createOption = async () => {
        if (!newOptionValue || newOptionValue === "")
            setError("Du måste ange en rabatt")
        else if (!numberOfEmployees || numberOfEmployees === "")
            setError("Du måste ange antal anställda");
        else {
            try {
                const res = await createData("/api/dsp/discounts", {
                    numberOfEmployees: numberOfEmployees,
                    discountPercentage: newOptionValue,
                });
        
                if (res?.status === 200) {
                    getDiscounts();
                    setNewOptionValue("");
                    setSuccess(res?.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 3500)
                } else {
                    console.log(res?.validation?.hasErrors);
                    if (res?.validation?.hasErrors)
                        setError(res?.validation?.messages[0])
                    else
                        setError(res?.message);
                    setTimeout(() => {
                        setError(null);
                    }, 3500)
                }   
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleOnChange = (e) => {
        setNewOptionValue(e.target.value);
    }

    const handleNumberOfEmployees = (e) => {
        setNumberOfEmployees(e.target.value);
    }

    useEffect(() => {
        let isMounted = true;

        if (!isAuthed) {
            navigate("/login");
        } else {
            if(isMounted) {
                getDiscounts();           
            }
        }

        return () => isMounted = false;
    }, [navigate, isAuthed, getDiscounts]);

    return (
        <div style={{ overflow: "scroll" }}>
            <h1>Rabatter</h1>
            {(!discounts || discounts?.length === 0) &&
                <>
                    <NewOptionContainer>
                        <div>
                            <input 
                                type="number" 
                                value={newOptionValue} 
                                onChange={handleOnChange} 
                                placeholder="Rabatt"
                            />
                            <input type="number" value={numberOfEmployees} onChange={handleNumberOfEmployees} placeholder="Antal anställda" />
                        </div>
                        <div>
                            <div onClick={createOption}><FcCheckmark /></div>
                        </div>
                    </NewOptionContainer>
                    {success && <p className="okay">{success}</p>}
                    {error && <p className="error">{error}</p>}
                </>
            }
            {(discounts && discounts?.length > 0) &&
                <Accordion 
                    heading={"Rabatt per antal anställda"} 
                    content={discounts} 
                    parentId={null} 
                    refetch={getDiscounts}
                    url={"/api/dsp/discounts"}
                    type="discounts"
                    openDefault={true}
                    sortKey="numberOfEmployees"
                />
            }
        </div>
    )
}

export default Discounts;

const NewOptionContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;

    > div:first-child {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        select {
            margin-bottom: 10px;
            border: none;
            padding: 5px 10px;
        }

        input {
            padding: 5px 10px;
        }
    }

    > div:last-child {
        background-color: #EEEEEE;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 50%;     
        
        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                font-size: 18px;
            }
        }
    }
`