import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { FcCheckmark } from "react-icons/fc";
import styled from "styled-components";

import { useAuth } from '../../context/auth-context';
import Accordion from '../../components/Accordion';
import { fetchData, createData } from '../../utils/fetchers';

const ENV = process.env.REACT_APP_PUBLIC_ENV;

const initialData = {
    name: "",
    code: "",
}

function Assortment() {
    const [assortmentOptions, setAssortmentOptions] = useState(null);
    const [newOptionValues, setNewOptionValues] = useState(initialData);
    const [newOptionParentId, setNewOptionParentId] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    const getAssortmentOptions = useCallback(async () => {
        const res = await fetchData("/api/dsp/assortment");

        if (res.status === 401) {
            sessionStorage.removeItem('cId');
            setIsAuthed(false);
            return navigate("/login");
        } else {
            setAssortmentOptions(res.data);
        }
    }, [navigate, setIsAuthed])

    const createOption = async () => {
        if (!newOptionValues?.name || newOptionValues.name === "")
            setError("Du måste ange ett namn")
        else if (!newOptionParentId || newOptionParentId === "")
            setError("Du måste välja en typ");
        else {
            try {
                const res = await createData("/api/dsp/assortment/types/options", {
                    assortmentTypeId: newOptionParentId,
                    code: newOptionValues.code,
                    name: newOptionValues.name,
                });
        
                if (res?.status === 200) {
                    getAssortmentOptions();
                    setNewOptionValues(initialData);
                    setSuccess(res?.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 3500)
                } else {
                    console.log(res?.validation?.hasErrors);
                    if (res?.validation?.hasErrors)
                        setError(res?.validation?.messages[0])
                    else
                        setError(res?.message);
                    setTimeout(() => {
                        setError(null);
                    }, 3500)
                }   
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleOnChange = (e) => {
        setNewOptionValues(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }

    const handleSelectOnChange = (e) => {
        setNewOptionParentId(e.target.value);
    }

    useEffect(() => {
        let isMounted = true;

        if (!isAuthed) {
            navigate("/login");
        } else {
            if(isMounted) {
                getAssortmentOptions();           
            }
        }

        return () => isMounted = false;
    }, [navigate, isAuthed, getAssortmentOptions]);

    return (
        <div style={{ overflow: "scroll" }}>
            <h1>Sortimentsval</h1>
            {(assortmentOptions && Object.keys(assortmentOptions)?.length < 11) &&
                <>
                    <NewOptionContainer>
                        <div>
                            <select name="parentId" id="parentId" onChange={handleSelectOnChange}>
                                {ENV === "local" ?
                                    <>
                                    <option value="">Välj typ...</option>
                                    <option value="103d8b54-0882-45bd-9b17-f230993036a3">Yrkesgrupper</option>
                                    <option value="0be2bd7b-9d6e-4236-bced-71a46f6cd964">Typ av produkter</option>
                                    <option value="97fbed50-f4c3-47b3-bab0-44a89f380f9f">Varselkrav</option>
                                    <option value="77c1a9e1-9365-4655-b524-01c7d14aeb45">Färger - Överdelar</option>
                                    <option value="94673a70-1cfa-4a81-ae3b-de04cb66d136">Accentfärger - Överdelar</option>
                                    <option value="9f84618e-7d24-4026-b131-acf91477cd92">Färger - Underdelar</option>
                                    <option value="726c79fe-78f7-4971-8e6c-a77d6e81a488">Accentfärger - Underdelar</option>
                                    <option value="3db5ad3a-1537-4b13-a42b-c33fa940a995">Färger - Varsel Klass 1</option>
                                    <option value="8c4fea8f-cbce-41ac-92df-c527ee2dde0d">Accentfärger - Varsel Klass 1</option>
                                    <option value="0c65df33-99ba-463d-a5c1-895b82aedc3d">Färger - Varsel Klass 2/3</option>
                                    <option value="134d7a47-200a-472f-9923-e555101ebcf4">Accentfärger - Varsel Klass 2/3</option>
                                    </>
                                : 
                                    <>
                                    <option value="">Välj typ...</option>
                                    <option value="aea5f4eb-c2fd-4b97-947b-a0fd28c2292f">Yrkesgrupper</option>
                                    <option value="1de14fba-495f-4a77-9bd2-ede62aa98a0e">Typ av produkter</option>
                                    <option value="1013dce9-1da3-4f15-a5b5-06f6f8241510">Varselkrav</option>
                                    <option value="c0951f2a-12d7-4f38-80ab-d1ba2e24a523">Färger - Överdelar</option>
                                    <option value="f2d82f68-01cd-4616-b40b-ce27f7b34c5d">Accentfärger - Överdelar</option>
                                    <option value="15cd06ec-031c-48ba-9830-13f77a8df81c">Färger - Underdelar</option>
                                    <option value="3d719f29-4c4f-44bc-8631-37ecbc755b06">Accentfärger - Underdelar</option>
                                    <option value="22da9f0b-e31b-476a-85df-0127b94c69c6">Färger - Varsel Klass 1</option>
                                    <option value="75f0948d-4e70-4065-b536-cfccb6d00864">Accentfärger - Varsel Klass 1</option>
                                    <option value="3500303b-1a46-46af-80aa-e6416d37bcf1">Färger - Varsel Klass 2/3</option>
                                    <option value="552eb562-7b03-4312-b724-ec27c2dd0866">Accentfärger - Varsel Klass 2/3</option>
                                    </>
                                }                
                            </select>
                            <input 
                                type="text" 
                                name="name"
                                value={newOptionValues.name} 
                                onChange={handleOnChange} 
                                placeholder="Namn"
                            />
                            <input 
                                type="text" 
                                name="code"
                                value={newOptionValues.code} 
                                onChange={handleOnChange} 
                                placeholder="Code"
                            />
                        </div>
                        <div>
                            <div onClick={createOption}><FcCheckmark /></div>
                        </div>
                    </NewOptionContainer>
                    {success && <p className="okay">{success}</p>}
                    {error && <p className="error">{error}</p>}
                </>
            }
            {assortmentOptions && Object.keys(assortmentOptions).length > 0 &&
                Object.keys(assortmentOptions).map(key =>
                    <Accordion 
                        key={key} 
                        heading={assortmentOptions[key][0].typeName} 
                        content={assortmentOptions[key]} 
                        parentId={assortmentOptions[key][0].assortmentTypeId} 
                        refetch={getAssortmentOptions}
                        url={"/api/dsp/assortment/types/options"}
                        type="assortment"
                    />
                )
            }
        </div>
    )
}

export default Assortment;

const NewOptionContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;

    > div:first-child {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        select {
            margin-bottom: 10px;
            border: none;
            padding: 5px 10px;
        }

        input {
            padding: 5px 10px;
        }
    }

    > div:last-child {
        background-color: #EEEEEE;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 50%;     
        
        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                font-size: 18px;
            }
        }
    }
`