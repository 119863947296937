import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { useAuth } from '../../context/auth-context';
import User from "../../components/users/User";

const Users = () => {

    const [users, setUsers] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState(null);
    const [searchString, setSearchString] = useState('');

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    const fetchUsers = useCallback(
        () => {
            fetch("/api/users")
            .then((res) => {
                if (res.status === 401) {
                    sessionStorage.removeItem('cId');
                    setIsAuthed(false);
                    navigate("/login");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                setUsers(data);
                setFilteredUsers(data);
            });
        }, [navigate, setIsAuthed],
    );

    useEffect(() => {
        if (!isAuthed) {
            navigate("/login");
        } else {
            fetchUsers();
        }
    }, [navigate, isAuthed, fetchUsers]);

    const filterCustomers = (e) => {
        const {value} = e.target;
        setSearchString(value);

        const filtered = users?.data?.filter(x => {
            return x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        });

        setFilteredUsers({
            ...filteredUsers,
            data: filtered
        })
    }

    return (
        <div>
            <UserSearchBarContainer>
                <h1>Användare</h1>
                <Input type="text" value={searchString} onChange={filterCustomers} placeholder="Sök användare" />
            </UserSearchBarContainer>
            <Container filteredCustomers={filteredUsers}>
                {filteredUsers?.data?.map(user => (
                    <User 
                        key={user.id} 
                        user={user} 
                        users={users} 
                        setUsers={setUsers} 
                        filteredUsers={filteredUsers} 
                        setFilteredUsers={setFilteredUsers} 
                    />
                ))}
            </Container>
        </div>
    )
}

const Container = styled.div`
    padding: 0 10px 30px 10px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    height: calc(100% - 77px);
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            width: 0 !important
        }
`

const UserSearchBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Input = styled.input`
    background: transparent;
    border: solid black 1px;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 14px;
    color: #eee;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    &:last-child {
        margin-bottom: 0;
    }

    &:focus {
        outline: none;
        background: #25272c;
    }

    &::placeholder {
        color: #ccc;
    }
`

export default Users;