import React, { useState, useEffect } from "react";
import { FcCheckmark, FcHighPriority } from "react-icons/fc";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { useAuth } from '../../context/auth-context';

const Create = () => {

    const initalFormValues = {
        name: "",
        id: '',
        companyId: ""
    }

    const [formValues, setFormValues] = useState(initalFormValues)
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null); 

    let navigate = useNavigate();
    const { isAuthed } = useAuth();

    const handleFormValueChange = (e) => {
        let value = e.target.value;
        if (e.target.name === 'id')
            value = parseInt(value);
        setFormValues({
          ...formValues,
          [e.target.name]: value
        });
    }

    const createCustomerRequest = (e) => {
        e.preventDefault();
        setResponse(null);
        setError(null);

        fetch('/api/customers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formValues),
        })
        .then(response => response.json())
        .then(data => {
            setResponse(data);
            setFormValues(initalFormValues);
        })
        .catch((err) => {
            console.error('Error:', err);
            setError(err)
        });
    }

    useEffect(() => {
        if (!isAuthed)
            navigate("/login");
    }, [isAuthed, navigate])

    return (
        <div>
            <h1>Skapa ny kund</h1>
            <FormContainer>
                <form onSubmit={createCustomerRequest}>
                    <div className="form-group">
                        <input 
                            type="text" 
                            value={formValues.name}
                            onChange={handleFormValueChange}
                            className="form-control-file" 
                            placeholder="Namn" 
                            name="name" 
                        />
                        <input 
                            type="number" 
                            value={formValues.id}
                            onChange={handleFormValueChange}
                            className="form-control-file" 
                            placeholder="Specter ID *" 
                            name="id" 
                        />
                        <input 
                            type="text" 
                            value={formValues.companyId} 
                            onChange={handleFormValueChange}
                            className="form-control-file" 
                            placeholder="BLK ID" 
                            name="companyId" 
                        />
                        <label>* = värdet måste vara ett nummer</label>
                        <div>
                            <input type="submit" value="Skapa kund" className="btn btn-default" />
                        </div>       
                    </div>
                </form>
                {response &&
                    <div>
                        {response.status === 200 ? <FcCheckmark size="1.5em" /> : <FcHighPriority size="1.5em" />}
                        <p>{response.message}</p>
                    </div>
                }
                {error &&
                    <div className="error">
                        <p>{error?.message}</p>
                    </div>
                }
            </FormContainer>
        </div>
    )
}

const FormContainer = styled.div`
    margin-top: 20px;
    padding: 50px;
    background-color: var(--color-bg-lighter);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    border-radius: 10px;
    position: relative;

    > div {
        display: flex;
        margin-top: 20px;

        p {
            color: var(--color-text-secondary);
            font-size: 13px;
            margin-left: 10px;
            display: flex;
            align-items: center;
        }
    }

    .form-group {
        display: flex;
        flex-direction: column;

        input {
            background: transparent;
            border: solid black 1px;
            border-radius: 10px;
            padding: 10px 15px;
            font-size: 14px;
            margin-bottom: 15px;
            color: #eee;
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);

            &:last-child {
                margin-bottom: 0;
            }

            &:focus {
                outline: none;
                background: #25272c;
            }
        }

        label {
            color: var(--color-text-gray);
            font-size: 12px;
            font-style: italic;
        }

        > div {
            display: flex;
            justify-content: end;
            align-items: center;
            margin-top: 40px;
        }

        .btn {
            border: solid var(--color-contrast-primary) 2px;
            background: transparent;
            padding: 15px;
            margin-bottom: 0;
            border-radius: 30px;
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            color: var(--color-text-primary);
            width: 200px;

            &:hover {
                background: var(--color-contrast-primary);
                cursor: pointer;
                color: var(--color-text-dark);
            }
        }
    }
`

export default Create;