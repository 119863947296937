import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
  } from "react-router-dom";

import '../src/App.css';
import { AuthProvider } from '../src/context/auth-context';
import Layout from './components/Layout';
import Home from './pages/Home';
import Imports from './pages/Imports';
import Customers from './pages/customers/Customers';
import Create from './pages/customers/Create';
import Users from './pages/users/Users';
import CreateUser from './pages/users/Create';
import Dsp from './pages/dsp';
import Reminders from './pages/dsp/Reminders';
import Assortment from './pages/dsp/Assortment';
import Settings from './pages/dsp/Settings';
import Vas from './pages/dsp/Vas';
import Sizes from './pages/dsp/Sizes';
import Discounts from './pages/dsp/Discounts';
import Faq from './pages/dsp/Faq';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import NewPassword from './pages/NewPassword';
import Error404 from './pages/error/404';

function App() {
    return (
        <Router>
            <AuthProvider>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/new-password" element={<NewPassword />} />
                        <Route path="/importer" element={<Imports />} />
                        <Route path="/kunder" element={<Customers />} />
                        <Route path="/kunder/ny-kund" element={<Create />} />
                        <Route path="/anvandare" element={<Users />} />
                        <Route path="/anvandare/ny-anvandare" element={<CreateUser />} />
                        <Route path="/dsp" element={<Dsp />} />
                        <Route path="/dsp/paminnelser" element={<Reminders />} />
                        <Route path="/dsp/sortiment" element={<Assortment />} />
                        <Route path="/dsp/installningar" element={<Settings />} />
                        <Route path="/dsp/vas" element={<Vas />} />
                        <Route path="/dsp/utprovning" element={<Sizes />} />
                        <Route path="/dsp/rabatter" element={<Discounts />} />
                        <Route path="/dsp/faq" element={<Faq />} />
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                </Layout>
            </AuthProvider>
        </Router>
    );
}

export default App;
