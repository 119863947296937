import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { FcCheckmark } from "react-icons/fc";
import styled from "styled-components";

import { useAuth } from '../../context/auth-context';
import Accordion from '../../components/Accordion';
import { fetchData, createData } from '../../utils/fetchers';

function Settings() {
    const [settings, setSettings] = useState(null);
    const [newOptionValue, setNewOptionValue] = useState("");
    const [newOptionDesc, setNewOptionDesc] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    const getSettings = useCallback(async () => {
        const res = await fetchData("/api/dsp/settings");

        if (res.status === 401) {
            sessionStorage.removeItem('cId');
            setIsAuthed(false);
            return navigate("/login");
        } else {
            setSettings(res.data);
        }
    }, [navigate, setIsAuthed])

    const createOption = async () => {
        if (!newOptionValue || newOptionValue === "")
            setError("Du måste ange ett namn")
        else if (!newOptionDesc || newOptionDesc === "")
            setError("Du måste ange en beskrivning");
        else {
            try {
                const res = await createData("/api/dsp/settings", {
                    name: newOptionValue,
                    desc: newOptionDesc,
                });
        
                if (res?.status === 200) {
                    getSettings();
                    setNewOptionValue("");
                    setSuccess(res?.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 3500)
                } else {
                    console.log(res?.validation?.hasErrors);
                    if (res?.validation?.hasErrors)
                        setError(res?.validation?.messages[0])
                    else
                        setError(res?.message);
                    setTimeout(() => {
                        setError(null);
                    }, 3500)
                }   
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleOnChange = (e) => {
        setNewOptionValue(e.target.value);
    }

    const handleDescOnChange = (e) => {
        setNewOptionDesc(e.target.value);
    }

    useEffect(() => {
        let isMounted = true;

        if (!isAuthed) {
            navigate("/login");
        } else {
            if(isMounted) {
                getSettings();           
            }
        }

        return () => isMounted = false;
    }, [navigate, isAuthed, getSettings]);

    return (
        <div style={{ overflow: "scroll" }}>
            <h1>Webshop - Inställningar</h1>
            {(!settings || settings?.length === 0) &&
                <>
                    <NewOptionContainer>
                        <div>
                            <input 
                                type="text" 
                                value={newOptionValue} 
                                onChange={handleOnChange} 
                                placeholder="Namn"
                            />
                            <textarea name="settingsDesc" id="settingDesc" cols="30" rows="10" placeholder='Beskrivning' onChange={handleDescOnChange}></textarea>
                        </div>
                        <div>
                            <div onClick={createOption}><FcCheckmark /></div>
                        </div>
                    </NewOptionContainer>
                    {success && <p className="okay">{success}</p>}
                    {error && <p className="error">{error}</p>}
                </>
            }
            {(settings && settings?.length > 0) &&
                <Accordion 
                    heading={"Inställningar"} 
                    content={settings} 
                    parentId={null} 
                    refetch={getSettings}
                    url={"/api/dsp/settings"}
                    type="settings"
                    openDefault={true}
                />
            }
        </div>
    )
}

export default Settings;

const NewOptionContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;

    > div:first-child {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        textarea {
            margin-bottom: 10px;
            border: none;
            padding: 5px 10px;
        }

        input {
            padding: 5px 10px;
        }
    }

    > div:last-child {
        background-color: #EEEEEE;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 50%;     
        
        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                font-size: 18px;
            }
        }
    }
`