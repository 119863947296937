import React from 'react';
import styled from 'styled-components';
import { MdDeleteOutline } from 'react-icons/md';

const User = ({ user, users, setUsers, filteredUsers, setFilteredUsers }) => {

    const deleteUser = (id) => {
        const customerToRemove = id;

        fetch(`/api/users/user/${id}`, {
            method: 'DELETE'
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                const newUsersList = users?.data?.filter(x => {
                    return x.id !== customerToRemove;
                })
                setUsers({
                    ...users,
                    data: newUsersList
                });
                setFilteredUsers({
                    ...filteredUsers,
                    data: newUsersList
                });
            }
        })
        .catch(err => {
            console.error(err);
        })
    }
    
    return (
        <UserContainer>
            <MdDeleteOutline onClick={() => deleteUser(user.id)} color="#eeeeee" />
            <h3>{user?.name}</h3>
            <p><span>ID: </span>{user?.id}</p>
            <p><span>Email: </span>{user?.email}</p>
            <p><span>Skapad: </span>{user?.created?.substring(0, 10)}</p>
        </UserContainer>
    )
}

const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--color-bg-lighter);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 20px;
    width: 31%;
    margin: 1%;
    max-height: 130px;
    color: var(--color-text-secondary);
    position: relative;
    border-radius: 10px;

    svg {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
    }

    h3 {
        font-size: 13px;
        margin-bottom: 5px;
    }

    p {
        font-size: 13px;
        display: flex;
        justify-content: space-between;
    }
`

export default User;