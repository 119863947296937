import React from 'react';
import styled from 'styled-components';
import { MdDeleteOutline } from 'react-icons/md';

const Customer = ({ customer, customers, setCustomers, filteredCustomers, setFilteredCustomers }) => {

    const deleteCustomer = (id) => {
        const customerToRemove = id;

        fetch(`/api/customers/${id}`, {
            method: 'DELETE'
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                const newCustomersList = customers?.data?.filter(x => {
                    return x.id !== customerToRemove;
                })
                setCustomers({
                    ...customers,
                    data: newCustomersList
                });
                setFilteredCustomers({
                    ...filteredCustomers,
                    data: newCustomersList
                });
            }
        })
        .catch(err => {
            console.error(err);
        })
    }
    
    return (
        <CustomerContainer>
            <MdDeleteOutline onClick={() => deleteCustomer(customer.id)} color="#eeeeee" />
            <h3>{customer?.name}</h3>
            <p><span>Specter ID: </span>{customer.specterId}</p>
            <p><span>BLK ID: </span>{customer?.companyId}</p>
            <p><span>Skapad: </span>{customer?.created?.substring(0, 10)}</p>
        </CustomerContainer>
    )
}

const CustomerContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--color-bg-lighter);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 20px;
    width: 31%;
    margin: 1%;
    max-height: 130px;
    color: var(--color-text-secondary);
    position: relative;
    border-radius: 10px;

    svg {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
    }

    h3 {
        font-size: 13px;
        margin-bottom: 5px;
    }

    p {
        font-size: 13px;
        display: flex;
        justify-content: space-between;
    }
`

export default Customer;