import { useState } from "react";
import { 
    FcExpand, 
    FcCollapse,
    FcAddRow,
    FcCheckmark,
    FcUpLeft
} from "react-icons/fc";
import styled from "styled-components";

import AccordionContent from "./AccordionContent";
import { createData, updateData, deleteData } from "../utils/fetchers";

const Accordion = ({ heading, content, parentId, refetch, url, type, openDefault = false, sortKey = "name" }) => {
    const [isOpen, setIsOpen] = useState(openDefault);
    const [createInput, setCreateInput] = useState(false);
    const [newOptionValue, setNewOptionValue] = useState("");
    const [newCodeOptionValue, setNewCodeOptionValue] = useState("");
    const [newOptionDesc, setNewOptionDesc] = useState("");
    const [newOptionGender, setNewOptionGender] = useState(""); 
    const [numberOfEmployees, setNumberOfEmployees] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const isArray = Array.isArray(content);

    const sortAlphabetical = (arr, key) => {
        return arr.sort((a, b) => {
            let sortA;
            let sortB;

            if (type === "discounts") {
                sortA = a[key];
                sortB = b[key];
            } else {
                sortA = a[key].toUpperCase(); // ignore upper and lowercase
                sortB = b[key].toUpperCase(); // ignore upper and lowercase
            }

            if (sortA < sortB) {
              return -1;
            }
            if (sortA > sortB) {
              return 1;
            }
          
            // names must be equal
            return 0;
        });
    }

    const getDataObject = async (type) => {
        let obj = {};
        
        switch (type) {
            case "assortment":
                obj = {
                    assortmentTypeId: parentId,
                    code: newCodeOptionValue,
                    name: newOptionValue,
                }
                break;
            case "settings":
                obj = {
                    name: newOptionValue,
                    desc: newOptionDesc,
                }
                break;
            case "faq":
                obj = {
                    question: newOptionValue,
                    answer: newOptionDesc,
                }
                break;
            case "vas":
                obj = {
                    vasTypeId: parentId,
                    code: newCodeOptionValue,
                    name: newOptionValue,
                }
                break;
            case "try-out":
                obj = {
                    tryOutTypeId: parentId,
                    name: newOptionValue,
                    desc: newOptionDesc,
                    gender: newOptionGender,
                }
                break;
            case "discounts":
                obj = {
                    numberOfEmployees: numberOfEmployees,
                    discountPercentage: newOptionValue,
                }
                break;
            default:
                break;
        }

        return obj;
    }

    const handleCreateInput = () => {
        setCreateInput(prev => !prev);
    }

    const handleOnChange = (e) => {
        setNewOptionValue(e.target.value);
    }

    const handleCodeOnChange = (e) => {
        setNewCodeOptionValue(e.target.value);
    }

    const handleDescOnChange = (e) => {
        setNewOptionDesc(e.target.value);
    }

    const handleGenderOnChange = (e) => {
        setNewOptionGender(e.target.value);
    }

    const handleEmployeesOnChange = (e) => {
        setNumberOfEmployees(e.target.value);
    }

    const createOption = async () => {
        if (!newOptionValue || newOptionValue === "")
            setError("Du måste ange ett namn")
        else if (type === "try-out" && (!newOptionGender || newOptionGender === ""))
            setError("Du måste välja herr eller dam")
        else if ((type === "try-out" || type === "settings" || type === "faq") && (!newOptionDesc || newOptionDesc === ""))
            setError(type === "faq" ? "Du måste ange att svar" : "Du måste ange en beskrivning")
        else {
            try {
                const data = await getDataObject(type);
                const res = await createData(url, data);
        
                if (res?.status === 200) {
                    refetch();
                    handleCreateInput();
                    setNewOptionValue("");
                    setSuccess(res?.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 3500)
                } else {
                    console.log(res?.validation?.hasErrors);
                    if (res?.validation?.hasErrors)
                        setError(res?.validation?.messages[0])
                    else
                        setError(res?.message);
                    setTimeout(() => {
                        setError(null);
                    }, 3500)
                }   
            } catch (err) {
                console.log(err);
            }
        }
    }

    const updateOption = async (id, data) => {
        const res = await updateData(`${url}/${id}`, data);

        if (res?.status === 200) {
            setSuccess(res?.message);
            setTimeout(() => {
                setSuccess(null);
            }, 3500);

            return "OK";
        } else {
            setError(res?.message);
            setTimeout(() => {
                setError(null);
            }, 3500);

            return "ERROR";
        }
    }

    const deleteOption = async (id) => {
        const res = await deleteData(`${url}/${id}`);

        if (res?.status === 200) {
            refetch();
            setSuccess(res?.message);
            setTimeout(() => {
                setSuccess(null);
            }, 3500);

            return "OK";
        } else {
            setError(res?.message);
            setTimeout(() => {
                setError(null);
            }, 3500);

            return "ERROR";
        }
    }

    return (
        <AccordionContainer>
            <div onClick={() => setIsOpen(!isOpen)}>
                <h4>{heading}</h4>
                {isOpen ? <FcCollapse /> : <FcExpand />}
            </div>
            {isArray 
                ?
                    isOpen ?
                        <div className="accordionContent">
                            <div>
                                {!createInput ?
                                    <span onClick={handleCreateInput}>Skapa ny <FcAddRow /></span> :
                                    <div>
                                        <div>
                                            <input 
                                                type={type === "discounts" ? "number" : "text"} 
                                                value={newOptionValue} 
                                                onChange={handleOnChange} 
                                                placeholder={type === "discounts" ? "Rabatt" : "Namn *"} 
                                            />
                                            {(type === "assortment" || type === "vas") &&
                                                <input 
                                                    type="text"
                                                    value={newCodeOptionValue} 
                                                    onChange={handleCodeOnChange} 
                                                    placeholder="Code" 
                                                />
                                            }
                                            {type === "try-out" &&
                                                <select name="gender" id="gender" onChange={handleGenderOnChange}>
                                                    <option>Välj herr eller dam...</option>
                                                    <option value="male">Herr</option>
                                                    <option value="female">Dam</option>
                                                </select>
                                            }
                                            {(type === "settings" || type === "try-out" || type === "faq") &&
                                                <textarea id="desc" name="desc" rows="5" cols="33" value={newOptionDesc} onChange={handleDescOnChange} placeholder={type === "faq" ? "Svar" : "Beskrivning"} />
                                            }
                                            {type === "discounts" &&
                                                <input type={"number"} value={numberOfEmployees} onChange={handleEmployeesOnChange} placeholder="Antal anställda" />
                                            }
                                        </div>
                                        <div>
                                            <div onClick={handleCreateInput}><FcUpLeft /></div>
                                            <div onClick={createOption}><FcCheckmark /></div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="accordionContentItems">
                                {sortAlphabetical(content, sortKey).map(x => 
                                    <AccordionContent 
                                        key={x.id} 
                                        data={x}
                                        updateOption={updateOption}
                                        deleteOption={deleteOption}
                                        type={type}
                                    />
                                )}
                            </div>
                            {success && <p className="okay">{success}</p>}
                            {error && <p className="error">{error}</p>}
                        </div> 
                    : null
                : <div className="accordionContent">{content}</div>
            }
            
        </AccordionContainer>
    )
}

export default Accordion;

const AccordionContainer = styled.div`
    margin-top: 20px;

    > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: var(--color-bg-dark-transparent);
        cursor: pointer;

        h4 {
            color: var(--color-text-secondary);
        }
    }

    .accordionContent {
        background-color: var(--color-bg-light-transparent);

        > p {
            margin-top: 15px;
        }

        > div {
            &:first-of-type {
                display: flex;
                justify-content: flex-start;
                padding: calc(1% + 10px) 0 0 calc(1% + 10px);

                > div {
                    display: flex;
                    align-items: center;

                    > div {
                        display: flex;

                        &:first-child {
                            flex-direction: column;

                            input, textarea {
                                font-size: 14px;
                                border: none;
                                padding: 3px 5px;

                                &:last-child {
                                    margin-top: 5px;
                                }
            
                                &:disabled {
                                    cursor: not-allowed;
                                }
                            }
                            
                            textarea {
                                margin-top: 5px; 
                            }
                        }

                        > div {
                            display: inherit;
                            align-items: center;

                            svg {
                                margin-left: 10px;
                                font-size: 24px;
                                cursor: pointer;
                            }
                        }
                    }

                    input, select {
                        padding: 3px 10px;
                        border: none;
                        font-size: 13px;
                    }

                    select {
                        margin-top: 5px;
                    }
                }

                > span {
                    display: flex;
                    font-size: 12px;
                    align-items: center;
                    cursor: pointer;

                    svg {
                        font-size: 24px;
                        margin-left: 5px;
                    }
                }
            }

            &:last-of-type {
                display: flex;
                flex-wrap: wrap;
                padding: 10px;
    
                > div {
                    margin: 1%;
                    display: flex;
                    align-items: center;
                    padding: 5px;
                    border-radius: 5px;
    
                    input, textarea {
                        font-size: 14px;
                        border: none;
                        padding: 3px 5px;
    
                        &:disabled {
                            cursor: not-allowed;
                        }
                    }
                    
                    textarea {
                        margin-top: 5px; 
                    }
    
                    > div {
                        display: flex;

                        &:first-child {
                            flex-direction: column;
                        }

                        &:last-child {
                            > div {
                                margin-left: 10px;
                                display: flex;
                                align-items: center;
        
                                svg {
                                    font-size: 20px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`