import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { FcCheckmark } from "react-icons/fc";
import styled from "styled-components";

import { useAuth } from '../../context/auth-context';
import Accordion from '../../components/Accordion';
import { fetchData, createData } from '../../utils/fetchers';

function Faq() {
    const [faqs, setFaqs] = useState(null);
    const [newQuestion, setNewQuestion] = useState("");
    const [newAnswer, setNewAnswer] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    const getFaqs = useCallback(async () => {
        const res = await fetchData("/api/dsp/faq");

        if (res.status === 401) {
            sessionStorage.removeItem('cId');
            setIsAuthed(false);
            return navigate("/login");
        } else {
            setFaqs(res.data);
        }
    }, [navigate, setIsAuthed])

    const createOption = async () => {
        if (!newQuestion || newQuestion === "")
            setError("Du måste ange en fråga")
        else if (!newAnswer || newAnswer === "")
            setError("Du måste ange en svar");
        else {
            try {
                const res = await createData("/api/dsp/faq", {
                    question: newQuestion,
                    answer: newAnswer,
                });
        
                if (res?.status === 200) {
                    getFaqs();
                    setNewQuestion("");
                    setNewAnswer("");
                    setSuccess(res?.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 3500)
                } else {
                    console.log(res?.validation?.hasErrors);
                    if (res?.validation?.hasErrors)
                        setError(res?.validation?.messages[0])
                    else
                        setError(res?.message);
                    setTimeout(() => {
                        setError(null);
                    }, 3500)
                }   
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleQuestionOnChange = (e) => {
        setNewQuestion(e.target.value);
    }

    const handleAnswerOnChange = (e) => {
        setNewAnswer(e.target.value);
    }

    useEffect(() => {
        let isMounted = true;

        if (!isAuthed) {
            navigate("/login");
        } else {
            if(isMounted) {
                getFaqs();           
            }
        }

        return () => isMounted = false;
    }, [navigate, isAuthed, getFaqs]);

    return (
        <div style={{ overflow: "scroll" }}>
            <h1>Webshop - Inställningar</h1>
            {(!faqs || faqs?.length === 0) &&
                <>
                    <NewOptionContainer>
                        <div>
                            <input 
                                type="text" 
                                value={newQuestion} 
                                onChange={handleQuestionOnChange} 
                                placeholder="Fråga"
                            />
                            <textarea name="answer" id="answer" cols="30" rows="10" placeholder='Svar' onChange={handleAnswerOnChange}></textarea>
                        </div>
                        <div>
                            <div onClick={createOption}><FcCheckmark /></div>
                        </div>
                    </NewOptionContainer>
                    {success && <p className="okay">{success}</p>}
                    {error && <p className="error">{error}</p>}
                </>
            }
            {(faqs && faqs?.length > 0) &&
                <Accordion 
                    heading={"Frågor & Svar"} 
                    content={faqs} 
                    parentId={null} 
                    refetch={getFaqs}
                    url={"/api/dsp/faq"}
                    type="faq"
                    openDefault={true}
                    sortKey="question"
                />
            }
        </div>
    )
}

export default Faq;

const NewOptionContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;

    > div:first-child {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        textarea {
            margin-bottom: 10px;
            border: none;
            padding: 5px 10px;
        }

        input {
            padding: 5px 10px;
        }
    }

    > div:last-child {
        background-color: #EEEEEE;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 50%;     
        
        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                font-size: 18px;
            }
        }
    }
`