import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { FcCheckmark, FcHighPriority, FcNext } from "react-icons/fc";
import styled from "styled-components";

// import { useAuth } from "../context/auth-context";

const NewPassword = () => {

    let [searchParams] = useSearchParams();
    let key = searchParams.get("key");
    let email = searchParams.get("email");

    const initalFormValues = {
        password: "",
        password2: ""
    }

    const [formValues, setFormValues] = useState(initalFormValues)
    const [responseData, setResponseData] = useState(null);
    const [responseStatus, setResponseStatus] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);

    let navigate = useNavigate();

    const handleFormValueChange = (e) => {
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value
        });

        const valid = e.target.name === 'password' ? e.target.value === formValues.password2 : e.target.value === formValues.password;
        if (valid && formValues.password !== "" && formValues.password2 !== "")
            setIsValid(true);
        else
            setIsValid(false);
    }

    const toLoginPage = () => {
        navigate("/login");
    }

    const resetPasswordRequest = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setResponseData(null);
        setError(null);

        fetch(`/api/users/reset-password?key=${key}&email=${email}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formValues),
        })
        .then(response => {
            setResponseStatus({
                status: response.status
            });
            return response.json();
        })
        .then(data => {
            setResponseData(data);
            setFormValues(initalFormValues);
            setIsLoading(false);
        })
        .catch((err) => {
            console.error('Error:', err);
            setError(err);
        });
    }

    return (
        <div>
            <h1>New password</h1>
            <FormContainer>
                {!isValid && formValues.password !== "" && formValues.password2 !== "" && <p className="passValidationError">Password doesn't match</p>}
                {isValid && formValues.password !== "" && formValues.password2 !== "" && <p className="passValidationSuccess">Password match</p>}
                <form onSubmit={resetPasswordRequest}>
                    <div className="form-group">
                        <input 
                            type="password" 
                            value={formValues.password}
                            onChange={handleFormValueChange}
                            className="userPassword" 
                            placeholder="Password" 
                            name="password" 
                        />
                        <input 
                            type="password" 
                            value={formValues.password2}
                            onChange={handleFormValueChange}
                            className="userPassword2" 
                            placeholder="Confirm password" 
                            name="password2" 
                        />
                        <input type="submit" disabled={isLoading || !isValid} value="Update password" className="btn btn-default" />            
                    </div>
                </form>
                {responseData &&
                    <div>
                        {responseStatus.status === 200 ? <FcCheckmark size="1.5em" /> : <FcHighPriority size="1.5em" />}
                        <p>{responseData.message}</p>
                        {responseStatus.status === 200 && <p className="loginLink" onClick={toLoginPage}>Login <FcNext /></p>}
                    </div>
                }
                {error &&
                    <div className="error">
                        <p>{error?.message}</p>
                    </div>
                }
            </FormContainer>
        </div>
    )
}

const FormContainer = styled.div`
    margin-top: 20px;
    padding: 50px;
    background-color: var(--color-bg-lighter);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    border-radius: 10px;
    position: relative;

    .passValidationError {
        color: var(--color-bg-error);
        margin-bottom: 10px;
    }

    .passValidationSuccess {
        color: var(--color-bg-success);
        margin-bottom: 10px;
    }

    > div {
        display: flex;
        margin-top: 20px;

        p {
            color: var(--color-text-secondary);
            font-size: 13px;
            margin-left: 10px;
            display: flex;
            align-items: center;
        }

        .loginLink {
            cursor: pointer;
            margin-left: 30px;

            svg {
                margin-left: 5px;
            }
        }
    }

    .form-group {
        display: flex;
        flex-direction: column;

        input {
            background: transparent;
            border: solid black 1px;
            border-radius: 10px;
            padding: 10px 15px;
            font-size: 14px;
            margin-bottom: 15px;
            color: #eee;
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);

            &:last-child {
                margin-bottom: 0;
            }

            &:focus {
                outline: none;
                background: #25272c;
            }
        }

        label {
            color: var(--color-text-gray);
            font-size: 12px;
            font-style: italic;
        }

        .btn {
            border: solid var(--color-contrast-primary) 2px;
            background: transparent;
            padding: 15px;
            border-radius: 30px;
            margin-top: 40px;
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            color: var(--color-text-primary);
            width: 200px;

            &:hover {
                background: var(--color-contrast-primary);
                cursor: pointer;
                color: var(--color-text-dark);
            }
        }

        input[type="submit"]:disabled {
            color: #999;
            border: solid #999 2px;

            &:hover {
                color: #999;
                background: transparent;
                cursor: not-allowed;
            }
        }
    }
`

export default NewPassword;