import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { useAuth } from '../../context/auth-context';
import Customer from "../../components/customers/Customer";

const Customers = () => {

    const [customers, setCustomers] = useState(null);
    const [filteredCustomers, setFilteredCustomers] = useState(null);
    const [searchString, setSearchString] = useState('');

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    const fetchCustomers = useCallback(
        () => {
            fetch("/api/customers")
            .then((res) => {
                if (res.status === 401) {
                    sessionStorage.removeItem('cId');
                    setIsAuthed(false);
                    return navigate("/login");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                setCustomers(data);
                setFilteredCustomers(data);
            });
        }, [navigate, setIsAuthed],
    );

    useEffect(() => {
        let isMounted = true;

        if (!isAuthed) {
            navigate("/login");
        } else {
            if(isMounted)
                fetchCustomers();
        }

        return () => isMounted = false;
    }, [navigate, isAuthed, fetchCustomers]);

    const filterCustomers = (e) => {
        const {value} = e.target;
        setSearchString(value);

        const filtered = customers?.data?.filter(x => {
            return x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 || x.companyId.indexOf(value) !== -1;
        });

        setFilteredCustomers({
            ...filteredCustomers,
            data: filtered
        })
    }

    return (
        <div>
            <CustomerSearchBarContainer>
                <h1>Kunder</h1>
                <Input type="text" value={searchString} onChange={filterCustomers} placeholder="Sök kund" />
            </CustomerSearchBarContainer>
            <Container filteredCustomers={filteredCustomers}>
                {filteredCustomers?.data?.map(customer => (
                    <Customer 
                        key={customer.id} 
                        customer={customer} 
                        customers={customers} 
                        setCustomers={setCustomers} 
                        filteredCustomers={filteredCustomers} 
                        setFilteredCustomers={setFilteredCustomers} 
                    />
                ))}
            </Container>
        </div>
    )
}

const Container = styled.div`
    padding: 0 10px 30px 10px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    height: calc(100% - 77px);
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            width: 0 !important
        }
`

const CustomerSearchBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Input = styled.input`
    background: transparent;
    border: solid black 1px;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 14px;
    color: #eee;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    &:last-child {
        margin-bottom: 0;
    }

    &:focus {
        outline: none;
        background: #25272c;
    }

    &::placeholder {
        color: #ccc;
    }
`

export default Customers;