import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { FcCheckmark } from "react-icons/fc";
import styled from "styled-components";

import { useAuth } from '../../context/auth-context';
import Accordion from '../../components/Accordion';
import { fetchData, createData } from '../../utils/fetchers';

function Sizes() {
    const [tryOutOptions, setTryOutOptions] = useState(null);
    const [newOptionValue, setNewOptionValue] = useState("");
    const [newOptionParentId, setNewOptionParentId] = useState("");
    const [newOptionGender, setNewOptionGender] = useState("");
    const [newOptionDesc, setNewOptionDesc] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    const getTryOutOptions = useCallback(async () => {
        const res = await fetchData("/api/dsp/try-out");

        if (res.status === 401) {
            sessionStorage.removeItem('cId');
            setIsAuthed(false);
            return navigate("/login");
        } else {
            setTryOutOptions(res.data);
        }
    }, [navigate, setIsAuthed])

    const createOption = async () => {
        if (!newOptionValue || newOptionValue === "")
            setError("Du måste ange ett namn")
        else if (!newOptionParentId || newOptionParentId === "")
            setError("Du måste välja en typ");
        else if (!newOptionGender || newOptionGender === "")
            setError("Du måste välja herr eller dam")
        else if (!newOptionDesc || newOptionDesc === "")
            setError("Du måste ange en beskrivning")
        else {
            try {
                const res = await createData("/api/dsp/try-out/types/options", {
                    tryOutTypeId: newOptionParentId,
                    name: newOptionValue,
                    desc: newOptionDesc,
                    gender: newOptionGender,
                });
        
                if (res?.status === 200) {
                    getTryOutOptions();
                    setNewOptionValue("");
                    setSuccess(res?.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 3500)
                } else {
                    console.log(res?.validation?.hasErrors);
                    if (res?.validation?.hasErrors)
                        setError(res?.validation?.messages[0])
                    else
                        setError(res?.message);
                    setTimeout(() => {
                        setError(null);
                    }, 3500)
                }   
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleOnChange = (e) => {
        setNewOptionValue(e.target.value);
    }

    const handleParentOnChange = (e) => {
        setNewOptionParentId(e.target.value);
    }

    const handleGenderOnChange = (e) => {
        setNewOptionGender(e.target.value);
    }

    const handleDescOnChange = (e) => {
        setNewOptionDesc(e.target.value);
    }

    useEffect(() => {
        let isMounted = true;

        if (!isAuthed) {
            navigate("/login");
        } else {
            if(isMounted) {
                getTryOutOptions();           
            }
        }

        return () => isMounted = false;
    }, [navigate, isAuthed, getTryOutOptions]);

    return (
        <div style={{ overflow: "scroll" }}>
            <h1>Utprovningsstorlekar</h1>
            {(!tryOutOptions || tryOutOptions?.length < 3) &&
                <>
                    <NewOptionContainer>
                        <div>
                            <input 
                                type="text" 
                                value={newOptionValue} 
                                onChange={handleOnChange} 
                                placeholder="Namn"
                            />
                            <select name="parentId" id="parentId" onChange={handleParentOnChange}>
                                <option value="">Välj typ...</option>
                                <option value="bc9726a2-6e9a-480d-9880-8a7f35c3d23e">Överdelar</option>
                                <option value="abda5f52-4cda-496e-b161-989e11918703">Underdelar</option>
                                <option value="a0d18993-e69e-4c15-ab0e-ef874b70560e">Skor</option>
                                
                                {/* DEV */}
                                {/* <option value="acfaf180-6b47-4666-a5ba-c1831d1ece51">Överdelar</option>
                                <option value="1e6e899d-a432-4ad7-8e89-16374ec55196">Underdelar</option>
                                <option value="7f761f7e-8d5d-4f16-93c1-9cf56ae6b437">Skor</option> */}
                            </select>
                            <select name="gender" id="gender" onChange={handleGenderOnChange}>
                                <option>Välj herr eller dam...</option>
                                <option value="male">Herr</option>
                                <option value="female">Dam</option>
                            </select>
                            <textarea name="sizeDesc" id="sizeDesc" cols="30" rows="10" placeholder='Beskrivning' onChange={handleDescOnChange}></textarea>
                        </div>
                        <div>
                            <div onClick={createOption}><FcCheckmark /></div>
                        </div>
                    </NewOptionContainer>
                    {success && <p className="okay">{success}</p>}
                    {error && <p className="error">{error}</p>}
                </>
            }
            {(tryOutOptions && tryOutOptions.length > 0) &&
                tryOutOptions.map(x =>
                    <Accordion 
                        key={x.id} 
                        heading={x.name} 
                        content={x.ranges} 
                        parentId={x.id} 
                        refetch={getTryOutOptions}
                        url={"/api/dsp/try-out/types/options"}
                        type="try-out"
                    />
                )
            }
        </div>
    )
}

export default Sizes;

const NewOptionContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;

    > div:first-child {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        select {
            margin: 10px 0;
            border: none;
            padding: 5px 10px;
        }

        input {
            padding: 5px 10px;
        }
    }

    > div:last-child {
        background-color: #EEEEEE;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 50%;     
        
        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                font-size: 18px;
            }
        }
    }
`