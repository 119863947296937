import React, { useState } from 'react';
import LogsItem from './LogsItem';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { FcDownload } from 'react-icons/fc';

const LogsList = ({logs, setOpen}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const downloadExcel = (e) => {
        e.preventDefault();
        setIsLoading(true);
      
        fetch('/api/export/excel/logs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(logs)
        })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(href => {
            setIsLoading(false);
            Object.assign(document.createElement('a'), {
                href,
                download: 'import_logs.xlsx',
            }).click();
        })
        .catch(err => {
            console.error(err);
            setError(err);
            setIsLoading(false);
        })
    }

    return (
        <LogsContainer>
            <div>
                <div>
                    <button onClick={downloadExcel} disabled={isLoading}><FcDownload size="1.5em" /> Download logs</button>
                    {error && <p>{error?.message}</p>}
                </div>
                <MdClose size="1.5em" onClick={() => setOpen(false)} />
            </div>
            {logs.map(log => (
                <LogsItem key={`${log.id} - ${log.message}`} log={log} />
            ))}
        </LogsContainer>
    )
}

const LogsContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    color: #bfbfbf;
    overflow: scroll;

    > div {
        display: flex;
        border-bottom: solid #222 1px;
        margin: 15px 30px;
        padding-bottom: 15px;
        font-size: 13px;
        align-items: center;

        &:first-child {
            position: sticky;
            top: 0;
            margin: 0;
            padding: 15px 20px;
            background-color: black;
            display: flex;
            justify-content: space-between;

            svg {
                cursor: pointer;
            }

            > div {
                display: flex;
                flex-direction: row;

                button {
                    display: flex;
                    align-items: center;
                    background: transparent;
                    border: none;
                    color: #eee;
                    cursor: pointer;

                    svg {
                        margin-right: 8px;
                    }
                }
            }
        }

        > div {
            display: flex;
            flex-direction: column;
            justifiy-content: space-between;
            width: 100%;
        }
    }
`

export default LogsList;