import React, { useState } from 'react';
import styled from "styled-components";
import { useReactTable, flexRender, getCoreRowModel } from '@tanstack/react-table';

import TableRow from './TableRow';

function Table({ columns, data }) {
    const [response, setResponse] = useState(null);

    const { getHeaderGroups, getRowModel } = useReactTable({ columns, data, getCoreRowModel: getCoreRowModel() });

    return (
        <TableContainer>
            <table>
                <thead>
                    {getHeaderGroups().map(hGroup => (
                        <tr key={hGroup.id}>
                            {hGroup.headers.map(header => (
                                <th key={`${header.column.columnDef.id}-${header.column.columnDef.header}`}>
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {getRowModel().rows.map(row => (
                        <TableRow key={row.id} row={row} flexRender={flexRender} setResponse={setResponse} />
                    ))}
                </tbody>
            </table>
            {
                response && (response?.status && response?.status === 200) ?
                    <p className='okay' style={{ marginTop: "10px" }}>{response.message}</p> : 
                response && (response?.status && response?.status !== 200) ? 
                    <p className='error' style={{ marginTop: "10px" }}>{response.message}</p> : null
            }
        </TableContainer>
    )
}

export default Table;

const TableContainer = styled.div`
    padding: 20px 0;
    height: 100%;
    overflow: scroll;

    table {
        width: 100%;

        thead tr {
            background-color: rgba(0,0,0,0.4);

            th {
                text-align: left;
                padding: 10px;
                color: var(--color-text-primary);
                font-size: 14px;
            }
        }

        tbody tr {
            background-color: rgba(0,0,0,0.3);

            td {
                padding: 10px;
                color: var(--color-text-primary);
                font-size: 14px;
            }
        }
    }
`