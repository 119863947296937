import React, {useState, useEffect} from "react";
import styled from 'styled-components';
import { FcFile, FcHighPriority, FcCheckmark } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

import LogsList from "../components/imports/LogsList";
import { useAuth } from '../context/auth-context';

const Imports = () => {

    const [uploadedFile, setUploadedFile] = useState('');
    const [logs, setLogs] = useState(null);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingText, setIsLoadingText] = useState(false);

    let navigate = useNavigate();
    const { isAuthed } = useAuth();

    const showUpladedFileInfo = (e) => {
        e.preventDefault();

        const [file] = e.target.files;
        const { name: fileName, size } = file;
        const fileSize = (size / 1000).toFixed(2);
        const fileNameAndSize = `${fileName} - ${fileSize}KB`;
        setUploadedFile(fileNameAndSize);
    }

    let timeoutId;
    if (isLoading)
        timeoutId = setTimeout(() => {setIsLoadingText(true)}, 5000);

    const importRequest = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const files = e.target[0].files;
        const formData = new FormData();
        formData.append('import_orders', files[0]);
      
        fetch('/api/import/orders', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setLogs(data);
            setIsLoading(false);
            setIsLoadingText(false);
        })
        .catch(err => {
            console.error(err);
            setError(err);
            setIsLoading(false);
            setIsLoadingText(false);
            clearTimeout(timeoutId);
        })
    }

    useEffect(() => {
        if (!isAuthed)
            navigate("/login");
    }, [isAuthed, navigate])

    const totalOrders = logs ? logs.logs?.filter(log => (log.type === "order" || log.type === "customer")) : [];
    const successfullOrders = totalOrders.length ? totalOrders.filter(order => (order.status === "1" || order.status === "2")) : [];

    return (
        <div>
            <h1>Import</h1>
            <FormContainer>
                <form method="POST" onSubmit={importRequest} encType="multipart/form-data">
                    <div className="form-group">
                        <div>
                            <input type="file" id="fileImport" onChange={showUpladedFileInfo} className="form-control-file" name="import_orders" />
                            <label htmlFor="fileImport">Välj fil</label>
                            {uploadedFile && <p><FcFile />{uploadedFile}</p>}
                        </div>
                        <div>
                            <input type="submit" disabled={isLoading} value={isLoading ? "Importerar..." : "Importera"} className="btn btn-default" />
                            {isLoading && isLoadingText && <span>Håll ut. Importen kan ta ett tag.</span>}
                        </div> 
                    </div>
                </form>
                {logs &&
                    <div>
                        <div className="logsHeaderButtonContainer">
                            <h3>Import-loggar</h3>
                            <button onClick={() => setOpen(true)}>Se alla loggar</button>
                        </div>
                        <p>{logs.message}</p>
                        <p><span>{successfullOrders?.length !== totalOrders?.length ? <FcHighPriority size="1.5em" /> : <FcCheckmark size="1.5em" />}</span>{successfullOrders?.length} av {totalOrders?.length} order importerade.</p>
                        {open &&
                            <LogsList logs={logs.logs} setOpen={setOpen} />
                        }
                    </div>
                }
                {error &&
                    <div className="error">
                        <p>{error?.message}</p>
                    </div>
                }
            </FormContainer>
        </div>    
    )
}

const FormContainer = styled.div`
    margin-top: 20px;
    padding: 50px;
    background-color: var(--color-bg-lighter);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    border-radius: 10px;
    position: relative;

    .form-group {
        display: flex;
        flex-direction: column;

        > div:first-child {
            display: flex;
            padding: 30px 20px;
            background-color: var(--color-bg-lightest);
            border: dotted var(--color-text-dark);
            border-radius: 10px;
            justify-content: center;
            align-items: center;

            p {
                color: var(--color-text-secondary);
                font-size: 14px;
                margin-left: 30px;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }
            }
        }

        > div:last-child {
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: end;
            margin-top: 40px;

            span {
                color: var(--color-text-secondary);
                font-size: 0.8rem;
                margin-top: 10px;
            }
        }

        .btn {
            border: solid var(--color-contrast-primary) 2px;
            background: transparent;
            padding: 15px;
            margin-bottom: 0;
            border-radius: 30px;
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            color: var(--color-text-primary);
            width: 200px;

            &:hover {
                background: var(--color-contrast-primary);
                cursor: pointer;
                color: var(--color-text-dark);
            }
        }

        input:disabled {
            color: #999;
            border: solid #999 2px;

            &:hover {
                color: #999;
                background: transparent;
                cursor: not-allowed;
            }
        }

        label {
            display: block;
            position: relative;
            width: 200px;
            padding: 15px;
            border-radius: 25px;
            background: linear-gradient(40deg, #69b47c, #0f2554);
            box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: bold;
            cursor: pointer;
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        }

        .form-control-file {
            opacity: 0;
            width: 0.1px;
            height: 0.1px;
            position: absolute;
        }
    }

    .loading {
        color: #bfbfbf;
        margin-top: 30px;
    }

    > div {
        margin-top: 40px;
        color: var(--color-text-secondary);

        p {
            font-size: 14px;
        }

        p:last-child {
            margin-top: 15px;
            display: flex;
            align-items: center;

            span {
                margin-right: 10px;
            }
        }

        .logsHeaderButtonContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            > button {
                font-size: 13px;
                font-weight: bold;
                border: none;
                background-color: var(--color-bg-lightest);
                border-radius: 10px;
                padding: 5px 10px;
                color: var(--color-text-secondary);
                cursor: pointer;
            }
        }
    }
`

export default Imports;