import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { 
    FcCollaboration, 
    FcImport, 
    FcNext, 
    FcPortraitMode, 
    FcDownRight, 
    FcBusinessman,
    FcGoodDecision,
} from "react-icons/fc";
import { useNavigate } from "react-router-dom";

import { useAuth } from '../context/auth-context';
import { routes } from '../utils/data';

const Layout = ({ children }) => {

    const [user, setUser] = useState();
    const [responseStatus, setResponseStatus] = useState(null);

    const { isAuthed, setIsAuthed } = useAuth();
    let navigate = useNavigate();

    const customerId = sessionStorage.getItem('cId');

    const setNavIcon = (routeId) => {
        
        let icon = null;

        switch (routeId) {
            case 1:
                icon = <FcImport size="1.5em" />;
                break;
            case 2:
                icon = <FcCollaboration size="1.5em" />;
                break;
            case 3:
            case 5:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
                icon = <FcDownRight size="1.5em" />
                break;
            case 4:
                icon = <FcBusinessman size="1.5em" />
                break;
            case 6:
                icon = <FcGoodDecision size="1.5em" />
                break;
            default:
                icon = <FcNext size="1.5em" />;
        }

        return icon;
    } 

    const fetchUser = useCallback(
        () => {
            fetch(`/api/users/user/${customerId}`)
            .then((res) => {
                if (res.status === 401) {
                    sessionStorage.removeItem('cId');
                    setIsAuthed(false);
                    navigate("/login");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                setUser(data);
            });
        }, [customerId, navigate, setIsAuthed],
    );

    let mounted = useRef(true);

    useEffect(() => {
        if (customerId && customerId !== "undefined" && isAuthed) {
            if (mounted.current) {
                mounted.current = false;
                fetchUser();
            }
        }
    }, [customerId, fetchUser, isAuthed])

    const logout = () => {
        fetch('/api/users/logout')
            .then((res) => {
                if (res.status === 405)
                    setResponseStatus({
                        status: 405
                    })
                
                return res.json();
            })
            .then((data) => {
                if (responseStatus?.status === 405) {
                    alert(data.message);
                } else {
                    sessionStorage.removeItem('cId');
                    setIsAuthed(false);
                    setUser();
                    mounted.current = true;
                }
            });
    }

    return (
        <>  
            <GlobalStyles />
            <FlexContainer>
                <SideNav>
                    <div>
                        <LogoContainer>
                            <Link to="/"><img src={`${process.env.REACT_APP_PUBLIC_URL}/images/Gear-01.png`} alt="Gear Shift AB" /></Link>
                        </LogoContainer>
                        {isAuthed &&
                            <ul>
                                {routes.map(route => (
                                    <div key={route.name}>
                                        {route.id !== 4 && <Link to={route.path}><li>{setNavIcon(route.id)}{route.name}</li></Link>}
                                        {route.id === 4 && user?.data[0]?.id === 1 && <Link to={route.path}><li>{setNavIcon(route.id)}{route.name}</li></Link>}
                                        {route.children &&
                                            <div className="subNav" key={route.id}>
                                                <ul>
                                                    {route.children?.map(child => (
                                                        <div key={child.id}>
                                                            {child.id !== 5 && <Link to={child.path}><li >{setNavIcon(child.id)}{child.name}</li></Link>}
                                                            {child.id === 5 && user?.data[0]?.id === 1 && <Link to={child.path}><li>{setNavIcon(child.id)}{child.name}</li></Link>}
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </ul>
                        }
                    </div>
                    {isAuthed &&
                        <div>
                            <button className="btn btn-default" onClick={logout}>Logout</button>
                        </div>
                    }
                </SideNav>
                <section className="page">
                    <TopNav>
                        <span>Adminverktyg</span>
                        {isAuthed && user &&
                            <div>
                                <span>{user?.data[0]?.name}</span>
                                <FcPortraitMode size="1.5em" />
                            </div>
                        }
                    </TopNav>
                    <PageContentContainer>
                        {children}
                    </PageContentContainer>
                </section>
            </FlexContainer>
        </>
    )
}

export default Layout;

const GlobalStyles = createGlobalStyle`
    html {
        --color-text-primary: #eeeeee;
        --color-text-secondary: #bfbfbf;
        --color-text-dark: #000000;
        --color-text-gray: #636772;
        --color-bg-dark: hsl(200,11.11%,10.59%);
        --color-bg-dark-transparent: rgba(0,0,0,0.1);
        --color-bg-light-transparent: rgba(255,255,255,0.1);
        --color-bg-light: hsl(222,10%,19.61%);
        --color-bg-lighter: hsl(222,10%,20.61%);
        --color-bg-lightest: hsl(222.86,11.86%,23.14%);
        --color-bg-error: hsl(0,28.57%,53.33%);
        --color-bg-success: hsl(135.25,23.69%,48.82%);
        --color-contrast-primary: hsl(135.2,33.33%,55.88%);
    }
`

const LogoContainer = styled.div`
    width: 100%;
    max-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    img {
        max-width: 100px;
    }
`

const TopNav = styled.nav`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    background-color: var(--color-bg-lightest);
    padding: 10px 15px;
    border-radius: 20px;

    > div {
        display: flex;
        align-items: center;
    }

    span {
        font-size: 13px;
        color: var(--color-text-gray);
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
`

const FlexContainer = styled.div`
    display: flex;
    width: 95vw;
    height: 90vh;
    border-radius: 30px;
    justify-content: space-between;
`

const PageContentContainer = styled.div`
    height: calc(100% - 44px);

    > div {
        height: 100%;
    }
`

const SideNav = styled.nav`
    width: 240px;
    border-radius: 30px;
    background-color: var(--color-bg-dark-transparent);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div:first-child {
        height: 80%;

        > ul {
            display: flex;
            flex-direction: column;
            height: 80%;
            overflow: scroll;
            padding: 0 0 30px 0;
    
            > div {
                width: 100%;
            }
    
            a {
                text-decoration: none;
                color: var(--color-text-primary);
                font-size: 13px;
    
                li {
                    padding: 10px 30px;
                    width: 100%;
                    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
                    display: flex;
                    align-items: center;
        
                    svg {
                        margin-right: 10px;
                    }
        
                    &:hover {
                        background-color: var(--color-bg-lightest);
                    }
        
                    
                }
            }
    
            .subNav {
                width: 100%;
    
                ul li {
                    padding: 10px 50px;
                }
            }
        }
    }

    > div:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        margin-bottom: 20px;
        height: 20%;

        .btn {
            border: solid var(--color-bg-lightest) 2px;
            background: var(--color-bg-lightest);
            padding: 10px 30px;
            border-radius: 30px;
            margin-top: 40px;
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            color: var(--color-text-primary);
            font-size: 14px;
            box-shadow: 0 8px 14px rgb(0 0 0 / 5%), 0 10px 10px rgb(0 0 0 / 12%);

            &:hover {
                box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
                cursor: pointer;
            }
        }
    }
`