import React, { useState, createContext, useContext } from 'react';

const AuthContext = createContext();

function AuthProvider({children}) {
    const [isAuthed, setIsAuthed] = useState(() => {
        const customerId = sessionStorage.getItem('cId');
        if (customerId && customerId !== "undefined")
            return true;
        
        return false;
    });
    return <AuthContext.Provider value={{ isAuthed, setIsAuthed }}>{children}</AuthContext.Provider>
}

function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    return context;
}

export {AuthProvider, useAuth};