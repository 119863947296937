export const fetchData = async (endpoint) => await fetch(endpoint, {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'GET',
        'Access-Control-Request-Headers': 'origin, x-requested-with',
        'Origin': process.env.REACT_APP_PUBLIC_URL
    }
}).then(res => res.json());

export const createData = async (endpoint, data) => await fetch(endpoint, {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'origin, x-requested-with',
        'Origin': process.env.REACT_APP_PUBLIC_URL
    },
    body: JSON.stringify(data),
}).then(res => res.json());

export const updateData = async (endpoint, data) => await fetch(endpoint, {
    method: 'PUT',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'PUT',
        'Access-Control-Request-Headers': 'origin, x-requested-with',
        'Origin': process.env.REACT_APP_PUBLIC_URL
    },
    body: JSON.stringify(data),
}).then(res => res.json());

export const deleteData = async (endpoint) => await fetch(endpoint, {
    method: 'DELETE',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'DELETE',
        'Access-Control-Request-Headers': 'origin, x-requested-with',
        'Origin': process.env.REACT_APP_PUBLIC_URL
    },
}).then(res => res.json());