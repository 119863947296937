export const routes = [
    {
        id: 1,
        path: "/importer",
        name: "Importer"
    },
    {
        id: 2,
        path: "/kunder",
        name: "Kunder",
        children: [
            {
                id: 3,
                path: "/kunder/ny-kund",
                name: "Ny kund"
            }
        ]
    },
    {
        id: 6,
        path: "/dsp",
        name: "DSP",
        children: [
            {
                id: 7,
                path: "/dsp/paminnelser",
                name: "Påminnelser"
            },
            {
                id: 8,
                path: "/dsp/sortiment",
                name: "Sortiment"
            },
            {
                id: 9,
                path: "/dsp/installningar",
                name: "Inställningar"
            },
            {
                id: 10,
                path: "/dsp/vas",
                name: "VAS"
            },
            {
                id: 11,
                path: "/dsp/utprovning",
                name: "Utprovning"
            },
            {
                id: 12,
                path: "/dsp/rabatter",
                name: "Rabatter"
            },
            {
                id: 13,
                path: "/dsp/faq",
                name: "FAQ"
            },
        ]
    },
    {
        id: 4,
        path: "/anvandare",
        name: "Användare",
        children: [
            {
                id: 5,
                path: "/anvandare/ny-anvandare",
                name: "Ny användare"
            }
        ]
    },
]